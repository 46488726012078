import { AuthenticationService } from 'src/app/authentication/shared/authentication.service';
import { AlertService } from './../alert/alert.service';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
} from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { AlertMessage } from '../alert/shared/alert-message';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private alertService: AlertService,
        private authenticationService: AuthenticationService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req)
            .pipe(
                tap(
                    success => success,
                    error => {
                      this.authenticationService.logout();
                      this.authenticationService.getLoginUrl().subscribe((loginUrl) => {
                          setTimeout(() => {
                            document.location.href = loginUrl;
                          }, 100);
                      });
                        if(error.status == 401) {
                        const alert = new AlertMessage();
                        alert.message = 'Sessão expirada';
                        alert.showMessage = true;
                        this.alertService.showAlertMessage(alert);
                        this.authenticationService.logout();
                            this.authenticationService.getLoginUrl().subscribe((loginUrl) => {
                                setTimeout(() => {
                                  document.location.href = loginUrl;
                                }, 100);
                            });
                        }
                    },
                )
            );
    }
}
