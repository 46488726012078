import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AlertMessage } from './shared/alert-message';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() {
    this.listenShowAlert();
  }

  public showAlert: Subject<AlertMessage> = new Subject<AlertMessage>();
  public alert$ = this.showAlert.asObservable();

  public getAlertStatus(): Observable<AlertMessage> {
    return this.showAlert.asObservable();
  }

  public showAlertMessage(alertMessage: AlertMessage): void {
    this.showAlert.next(alertMessage);
  }

  public hiddenAlert(): void {
    const alertMessage = new AlertMessage();
    alertMessage.showMessage = false;
    this.showAlert.next(alertMessage);
  }

  public listenShowAlert(): void {
    this.showAlert.pipe(debounceTime(5000), distinctUntilChanged()).subscribe(() => {
      this.hiddenAlert();
    });
  }

}
