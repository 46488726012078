import { PipesModule } from './pipes/pipes.module';
import { AlertModule } from './alert/alert.module';
import { HeaderModule } from './header/header.module';
import { HttpService } from './http/http-service.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OverlayModule } from '@angular/cdk/overlay';
import { IconServiceService } from './icon/icon-service.service';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [],
  imports: [
    CommonModule, HeaderModule, AlertModule, OverlayModule
  ],
  exports: [HeaderModule, AlertModule],
  providers: [HttpRequestInterceptor, HttpService, MatSnackBar, IconServiceService]
})
export class CoreModule { }
