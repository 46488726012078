<div>
  <header>
    <app-header></app-header>
  </header>
  <main>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </main>
  <footer>
    <app-alert></app-alert>
  </footer>
</div>
