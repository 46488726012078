import { Component } from '@angular/core';
import { IconServiceService } from './core/icon/icon-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private iconService: IconServiceService) {
    this.iconService.init();
  }
  title = 'Hub do executivo';
}
