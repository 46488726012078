import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { from, Observable } from 'rxjs';
import { CryptoService } from '../crypto/crypto.service';

const CODE_VERIFIER_LENGTH = 64;
const CODE_VERIFIER_KEY = 'pkce_code_verifier';
const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private codeChallenge = '';

  constructor(private http: HttpClient, private cryptoService: CryptoService) { }

  isLoggedIn(): boolean {
    return localStorage.getItem(ID_TOKEN_KEY) !== null;
  }

  login(authorization_code: string): Observable<void> {
    const codeVerifier = localStorage.getItem(CODE_VERIFIER_KEY);
    if (!codeVerifier) {
      throw 'Missing PKCE code verifier';
    }

    let body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', authorization_code)
      .set('redirect_uri', environment.redirectUrl)
      .set('client_id', environment.clientId)
      .set('client_secret', environment.clientSecret)
      .set('code_verifier', codeVerifier);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http
      .post(environment.providerUrl + '/token', body.toString(), {
        headers: headers,
      })
      .pipe(
        map((response: any) => {
          return response['access_token'];
        }),
        mergeMap((access_token: string) => {
          let body = new HttpParams()
            .set('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket')
            .set('audience', 'web-globoads');

          const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${access_token}`,
          });

          return this.http.post(
            environment.providerUrl + '/token',
            body.toString(),
            {
              headers: headers,
            }
          );
        }),
        map((response: any) => {
          const id_token = response['id_token'];
          const access_token = response['access_token'];

          localStorage.setItem(ID_TOKEN_KEY, id_token);
          localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
        })
      );
  }

  logout(): void {
    localStorage.removeItem(ID_TOKEN_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  getLoginUrl(): Observable<string> {
    let codeVerifier = this.cryptoService.generateRandom(CODE_VERIFIER_LENGTH);
    localStorage.setItem(CODE_VERIFIER_KEY, codeVerifier);

    return from(this.cryptoService.deriveChallenge(codeVerifier)).pipe(
      map((codeChallenge) => {
        this.codeChallenge = codeChallenge;

        let params = new URLSearchParams();
        params.set('scope', 'openid');
        params.set('response_type', 'code');
        params.set('client_id', environment.clientId);
        params.set('client_secret', environment.clientSecret);
        params.set('redirect_uri', environment.redirectUrl);
        params.set('code_challenge', this.codeChallenge);
        params.set('code_challenge_method', 'S256');
        params.set('kc_idp_hint', 'azuread-hubexecutivo');

        return environment.providerUrl + '/auth?' + params.toString();
      })
    );
  }

  getAccessToken(): any {
    const access_token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!!access_token) {
      return jwt_decode(access_token);
    }
    return null;
  }
}
