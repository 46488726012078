import { HeaderService } from './header.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IconComponent } from '../icon/icon-component/icon.component';
import { IconModule } from '../icon/icon.module';



@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    IconModule
  ],
  exports: [HeaderComponent],
  providers: [HeaderService]
})
export class HeaderModule { }
