import { Component, Input, OnInit } from '@angular/core';
import { CardTypeEnum } from '../../../products/shared/card-type.enum';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() iconName!: string | any;

  public screenCardEnum = CardTypeEnum;
  constructor() { }

  ngOnInit(): void {
  }

}
