<div [ngSwitch]="iconName">
  <mat-icon *ngSwitchCase="'file'" svgIcon="file"></mat-icon>
  <mat-icon *ngSwitchCase="'globo-ads-vertical'" class="globo-ads-vertical" svgIcon="globo-ads-vertical"></mat-icon>
  <mat-icon *ngSwitchCase="'hard-drive'" svgIcon="hard-drive"></mat-icon>
  <mat-icon *ngSwitchCase="'download'" svgIcon="download"></mat-icon>
  <mat-icon *ngSwitchCase="'folder'" svgIcon="folder"></mat-icon>
  <mat-icon *ngSwitchCase="'share'" class="share" svgIcon="share"></mat-icon>
  <mat-icon *ngSwitchCase="'logo'" class="logo" svgIcon="logo"></mat-icon>
  <mat-icon *ngSwitchCase="'news'" svgIcon="news"></mat-icon>
  <mat-icon *ngSwitchCase="'face-bored'" class="face-bored" svgIcon="face-bored"></mat-icon>
  <mat-icon *ngSwitchCase="'bg-login'" class="bg-login" svgIcon="bg-login"></mat-icon>
  <mat-icon *ngSwitchCase="screenCardEnum.DIGITAL || 'smartphone'" svgIcon="smartphone"></mat-icon>
  <mat-icon *ngSwitchCase="screenCardEnum.TV_ABERTA || 'tv'" svgIcon="tv"></mat-icon>
  <mat-icon *ngSwitchDefault svgIcon="tv"></mat-icon>
</div>
