import { AlertService } from './../alert/alert.service';
import { HeaderService } from './../header/header.service';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';

import { finalize, retryWhen, tap } from 'rxjs/operators';
import { AlertMessage } from '../alert/shared/alert-message';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private headerService: HeaderService, private alertService: AlertService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        this.headerService.showLoading();
        const started = Date.now();
        let success: boolean;

        // extend server response observable with logging
        return next.handle(req)
            .pipe(
                tap(
                    // Succeeds when there is a response; ignore other events                    
                    event => success = event instanceof HttpResponse ? success = true : success = false,
                    // Operation failed; error is an HttpErrorResponse
                    error => success = false,

                ),
                // Log when response observable either completes or errors
                finalize(() => {
                    const elapsed = Date.now() - started;
                    const msg = `${req.method} "${req.urlWithParams}"
                 ${String(success)} in ${elapsed} ms.`;
                    //console.log(msg); // only debug
                    this.headerService.hiddenLoading();
                    if (success === true && req.method !== 'GET') {
                        const alert = new AlertMessage();
                        alert.message = 'Sucesso';
                        alert.showMessage = true;
                        this.alertService.showAlertMessage(alert);
                    }
                })
            );
    }
}