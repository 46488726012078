// Adapted from: https://github.com/openid/AppAuth-JS/blob/master/src/crypto_utils.ts

import { Injectable } from '@angular/core';
import * as base64 from 'base64-js';

const HAS_CRYPTO = typeof window !== 'undefined' && !!(window.crypto as any);
const HAS_SUBTLE_CRYPTO = HAS_CRYPTO && !!(window.crypto.subtle as any);
const CHARSET =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export interface Crypto {
  /**
   * Generate a random string
   */
  generateRandom(size: number): string;
  /**
   * Compute the SHA256 of a given code.
   * This is useful when using PKCE.
   */
  deriveChallenge(code: string): Promise<string>;
}

@Injectable({
  providedIn: 'root',
})
export class CryptoService implements Crypto {
  constructor() {}

  urlSafe(buffer: Uint8Array): string {
    const encoded = base64.fromByteArray(new Uint8Array(buffer));
    return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  // adapted from source: http://stackoverflow.com/a/11058858
  // this is used in place of TextEncode as the api is not yet
  // well supported: https://caniuse.com/#search=TextEncoder
  textEncodeLite(str: string) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);

    for (let i = 0; i < str.length; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return bufView;
  }

  bufferToString(buffer: Uint8Array) {
    let state = [];
    for (let i = 0; i < buffer.byteLength; i += 1) {
      let index = buffer[i] % CHARSET.length;
      state.push(CHARSET[index]);
    }
    return state.join('');
  }

  generateRandom(size: number) {
    const buffer = new Uint8Array(size);
    if (HAS_CRYPTO) {
      window.crypto.getRandomValues(buffer);
    } else {
      // fall back to Math.random() if nothing else is available
      for (let i = 0; i < size; i += 1) {
        buffer[i] = (Math.random() * CHARSET.length) | 0;
      }
    }
    return this.bufferToString(buffer);
  }

  deriveChallenge(code: string): Promise<string> {
    if (code.length < 43 || code.length > 128) {
      return Promise.reject('Invalid code length.');
    }
    if (!HAS_SUBTLE_CRYPTO) {
      return Promise.reject('window.crypto.subtle is unavailable.');
    }

    return new Promise((resolve, reject) => {
      crypto.subtle.digest('SHA-256', this.textEncodeLite(code)).then(
        (buffer) => {
          return resolve(this.urlSafe(new Uint8Array(buffer)));
        },
        (error) => reject(error)
      );
    });
  }
}
