import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable, Injector, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class HttpService<T> {

  public httpClient: HttpClient;

  constructor(private injector: Injector, @Inject('url') public url: string, @Inject('endpoint') protected endpoint: string) {
    this.httpClient = injector.get(HttpClient);
  }

  public create(item: T): Observable<T> {
    if (this.endpoint === '' || null) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      return this.httpClient.post<T>(`${this.url}`, item).pipe(map((data) => data as T));
    } else {
      return this.httpClient.post<T>(`${this.url}/${this.endpoint}`, item).pipe(map((data) => data));
    }
  }

  public update(item: T): Observable<T> {
    if (this.endpoint === '' || null) {
      return this.httpClient.put<T>(`${this.url}`, item).pipe(map((data) => data));
    }
    else {
      return this.httpClient.put<T>(`${this.url}/${this.endpoint}`, item).pipe(map((data) => data));
    }
  }

  public updateWithId(item: T, id: string): Observable<T> {
    if (this.endpoint === '' || null) {
      return this.httpClient.put<T>(`${this.url}/${id}`, item).pipe(map((data) => data));
    }
    else {
      return this.httpClient.put<T>(`${this.url}/${this.endpoint}/${id}`, item).pipe(map((data) => data));
    }
  }

  public delete(item: string): Observable<T> {
    if (this.endpoint === '' || null) {
      return this.httpClient.delete<T>(`${this.url}/${item}`).pipe(map((data) => data));
    }
    else {
      return this.httpClient.delete<T>(`${this.url}/${this.endpoint}/${item}`).pipe(map((data) => data));
    }
  }

  public get(id: string): Observable<T> {
    if (this.endpoint === '' || null) {
      return this.httpClient.get<T>(`${this.url}/${id}`).pipe(map((data) => data));
    }
    else {
      return this.httpClient.get<T>(`${this.url}/${this.endpoint}/${id}`).pipe(map((data) => data));
    }
  }

  public getAll(): Observable<T[]> {
    if (this.endpoint === '' || null) {
      return this.httpClient.get<T[]>(`${this.url}`).pipe(map((data) => data));
    }
    else {
      return this.httpClient.get<T[]>(`${this.url}/${this.endpoint}`).pipe(map((data) => data));

    }
  }

  public getAllWithParams(paramsx: HttpParams): Observable<T[]> {
    if (this.endpoint === '' || null) {
      return this.httpClient.get<T[]>(`${this.url}`, { params: paramsx }).pipe(map((data) => data));
    }
    else {
      return this.httpClient.get<T[]>(`${this.url}/${this.endpoint}`, { params: paramsx }).pipe(map((data) => data));
    }
  }

  public getWithParams(paramsx: HttpParams): Observable<T> {
    if (this.endpoint === '' || null) {
      return this.httpClient.get<T>(`${this.url}`, { params: paramsx }).pipe(map((data) => data));
    }
    else {
      return this.httpClient.get<T>(`${this.url}/${this.endpoint}`, { params: paramsx }).pipe(map((data) => data));
    }
  }


}
