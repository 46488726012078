import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public isLoading: Subject<boolean> = new Subject<boolean>();
  public loading$ = this.isLoading.asObservable();

  public getLoadingStatus(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public showLoading(): void {
    this.isLoading.next(true);
  }

  public hiddenLoading(): void {
    this.isLoading.next(false);
  }

}
