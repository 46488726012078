import { AlertService } from './../alert.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { AlertMessage } from '../shared/alert-message';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements AfterContentInit {

  public alertMessage: AlertMessage = new AlertMessage();
  @ViewChild('item') item: any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';


  constructor(private alertService: AlertService, private cd: ChangeDetectorRef, private snackBar: MatSnackBar) { }

  public ngAfterContentInit(): void {

    this.alertService.alert$.subscribe(reqStatus => {
      this.alertMessage = reqStatus;
      this.verifySnackBar();
      this.cd.detectChanges();
    });
  }

  private verifySnackBar() {
    if (this.alertMessage.showMessage === true) {
      this.snackBar.open(this.alertMessage.message, 'Fechar', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else {
      this.snackBar.dismiss();
    }

  }
}



