// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  providerUrl:
    'https://iam.negocios.tvglobo.com.br/auth/realms/Globoads/protocol/openid-connect',
  clientId: 'web-globoads',
  redirectUrl: 'https://hub.ads.globo/auth/callback',

  clientSecret: 'cf1e67e1-211e-4fa9-824a-9dd49c56481a',
  urls: {
    homes: "https://api.prd.negocios.tvglobo.com.br/hub-executivo",
    search: "https://api.prd.negocios.tvglobo.com.br/hub-executivo"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
