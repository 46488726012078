import { Injectable } from '@angular/core';

import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    private token = localStorage.getItem('access_token');

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log(request.url.indexOf('realm'));
        //if (request.url.indexOf('realm') === -1 && request.url.indexOf('vitrine/v2') === -1) {
        if (request.url.indexOf('realm') == -1 ) {

          let token;
          if (!this.token) {
            token = localStorage.getItem('access_token');
          } else {
            token = this.token;
          }

            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }


        return next.handle(request);

    }

}
