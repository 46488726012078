import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconServiceService {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) { }

  init() {
    let icons = new Map<string, string>();
    icons.set(
      "file",
      "../assets/icons/arquivo.svg",
    );
    icons.set(
      "globo-ads-vertical",
      "../assets/icons/hub-ads-vertical.svg"
    );
    icons.set(
      "hard-drive",
      "../assets/icons/hard-drive.svg"
    )
    icons.set(
      "download",
      "../assets/icons/icon-download.svg"
    );
    icons.set(
      "folder",
      "../assets/icons/icon-folder.svg"
    );
    icons.set(
      "share",
      "../assets/icons/icon-share.svg"
    );
    icons.set(
      "logo",
      "../assets/icons/logo-globo-ads.svg"
    );
    icons.set(
      "news",
      "../assets/icons/news.svg"
    );
    icons.set(
      "smartphone",
      "../assets/icons/smartphone.svg"
    )
    icons.set(
      "tv",
      "../assets/icons/tv.svg"
    )
    icons.set(
      "face-bored",
      "../assets/imgs/face-bored.svg"
    )
    icons.set(
      "bg-login",
      "../assets/imgs/bg-login.svg"
    )
    Array.from(icons.entries())
      .forEach(entry => {
        this.matIconRegistry.addSvgIcon(
          entry[0],
          this.domSanitizer.bypassSecurityTrustResourceUrl(entry[1]),
        );
      });
  }
}

