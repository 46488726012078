<div>
  <mat-progress-bar mode="indeterminate" class="bar" item *ngIf="showLoading">
  </mat-progress-bar>

  <div class="hold-logo" *ngIf="!!currentUser?.given_name">
    <!-- <img src="../../../../assets/icons/logo-globo-ads.svg" alt="Logo Globo ads" /> -->

    <app-icon iconName="logo"></app-icon>
    <div class="button">
      <div class="row d-flex">
        <div class="ml-auto">
          <button (click)="logout()" class="button button-logout">Sair</button>
        </div>
      </div>
    </div>
  </div>
  <div class="hold-user-info" *ngIf="!!currentUser?.given_name">
    <span> Olá {{ currentUser?.given_name }}!</span> <br />
    O que deseja encontrar hoje?
  </div>
</div>
