import { AuthenticationService } from './../../../authentication/shared/authentication.service';
import { HeaderService } from './../header.service';
import { Component, OnInit, AfterContentInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterContentInit {
  public showLoading: boolean = false;
  @ViewChild('item') item: any;
  public currentUser: any;

  // TODO: CRIAR OBJETO USER
  public isAuthPage: boolean = false;

  constructor(private headerService: HeaderService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router) { }

  ngAfterContentChecked() {
    this.cd.detectChanges();
    this.currentUser = this.authenticationService.getAccessToken();
  }


  public ngAfterContentInit(): void {
    this.headerService.loading$.subscribe(reqStatus => {
      this.showLoading = reqStatus;
      this.cd.detectChanges();
    });

  }

  logout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/auth']);
  }

}
