import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './icon-component/icon.component';
import { IconServiceService } from './icon-service.service';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    IconComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    IconComponent
  ],
  providers: [
    IconServiceService
  ]
})
export class IconModule { }
